@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300&family=IBM+Plex+Sans+Arabic:wght@300&family=Karantina:wght@300&family=Philosopher:ital,wght@1,700&family=Red+Hat+Display:wght@300&family=Rubik&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


.flexItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::-webkit-scrollbar {
  width: 8px;
  /* عرض شريط التمرير */
}

::-webkit-scrollbar-thumb {
  background-color: #FECB44;
  /* لون شريط التمرير نفسه */
  border-radius: 10px;
  /* إضافة حواف مستديرة */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* لون خلفية شريط التمرير */
}

body {
  background-color: #E5E7EB !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.font {
  font-family: IBM Plex Sans Arabic;
  font-size: 14px;
  font-weight: bolder;
  line-height: 20px;
  letter-spacing: 0em;
}

h4 {
  font-family: IBM Plex Sans Arabic;
  font-size: 22px;
  font-weight: bolder;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;

}

/**Login Style */
.back-logo {
  width: 100%;
  height: 100vh;
  background-image: url(./Images/backShadowLogin\ -\ Copy.WebP);
  background-color: white !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.login {
  background-image: url(./Images/backShadowLogin\ -\ Copy.WebP);
  padding: 20px;
  position: absolute;
  width: 25%;
  margin: auto;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border: 1px solid #E5E7EB;
  box-shadow: 0px 24px 52px 0px #0000001F;
  height: 65vh;
}

.filedInputs {
  width: 85% !important;
}

.submitButton {
  background: #FECB44 !important;
  color: white !important;
  width: 80%;
  margin: 25px auto;
  text-align: center;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
  outline: none;
}

/**End Style Form Login */


/*Style Admin Side Bar */
.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 18.5% !important;
  max-height: 100vh !important;
  background-color: #ffffff;
  border-right: 1px solid #E5E7EB;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: 2s ease;
  -moz-transition: 2s ease;
  -ms-transition: 2s ease;
  -o-transition: 2s ease;
  -webkit-transition: 2s ease;
  z-index: 10 !important;
}

.sidebar.open {
  transform: translateX(85%);
  -webkit-transform: translateX(85%);
  -moz-transform: translateX(85%);
  -ms-transform: translateX(85%);
  -o-transform: translateX(85%);
  overflow-y: hidden;
}

.admin-side-text {
  color: black !important;
  font-family: IBM Plex Sans Arabic;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0em;
}

.header {
  color: #4B5563 !important;
  text-align: right !important;
}

.refresh {
  background-color: #FECB44 !important;
  color: black !important;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #FECB44;
}

.numSetting {
  background: #FDECCE;
  border-radius: 40px;
  padding: 4px 10px;
  text-align: center;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}

.circle {
  background-color: black;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.navBar {
  border-bottom: 1px solid #E5E7EB;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.bickerMab {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.accordion-button {
  padding: 2px !important;
  font-size: 50px !important;
}

.accordion-button::after {
  margin-left: 0 !important;
}

/*End Style Admin Side Bar */

/**Subs List */
.list {
  max-height: 70vh;
  overflow: auto;
}

.subsButton {
  border-radius: 10px;
  padding: 5px !important;
  margin-left: 3px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.BigInfoBycker {
  width: 100vw;
  padding: 10px;
  margin-bottom: 10px;
}

.byckerInfo {
  line-height: 10px;
  width: 25%;
  padding: 5px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: white;
  border: 1px solid #F1F1F2;
  box-shadow: 0px 3px 4px 0px #00000008;
  position: relative;
  padding-right: 15px !important;
}

.byckerInfo::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  margin-left: 15px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 5px;
  height: 98%;
}

.byckerAvailable {
  overflow: auto;
}

/**Subs List */

/**Style Statics */
.statics {
  background-color: white;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  min-height: 200px !important;
}

.smallUpStatic {
  color: #3CC441;

}

.smallDownStatic {
  color: #C43C3C;

}

.manage {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #FECB44;
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.accordion-item:first-of-type .accordion-button {
  background-color: transparent;
  border: none !important;
  outline: none !important;
}

/**End Style Statics */

/**Start Schudle Calender  */
.week-calendar {
  display: flex;
  flex-wrap: wrap;
}

.calendar-container {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.week-calendar-item {
  width: 100%;
}

/**Start Schudle Calender  */

/**chartData */
.chartData {
  width: 98%;
  margin: auto;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #F1F1F2;
  box-shadow: 0px 3px 4px 0px #00000008;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

/**chartData */

/**Calender Style */
.calender {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 4px 0px #00000008;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 20px 0px;
}

.calender select {
  background-color: #F1F1F2;
  padding: 15px 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: #7E8299;
}

.typebooking {
  position: relative;
  padding-right: 8px;
  margin-bottom: 10px;
}

.typebooking::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #3CC441;
  right: 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-top: -12px;
}

/**Calender Style */


/**Style Booking And Subs */
.tabContainer {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none !important;
  outline: none !important;
}

.nav-item button {
  border: none !important;
  outline: none !important;
  color: #828282;
  font-family: IBM Plex Sans Arabic;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0em;
}

.nav-item button:hover {
  color: black !important;
}

.nav-link.active {
  border-bottom: 2px solid #FECB44 !important;
}

.search-container {
  position: relative;
  display: inline-block;
}

.search-icon {
  position: absolute;
  left: -2%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: #FECB44;
  padding: 10px 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  width: 50px;
  font-size: 50px !important;
  height: 100%;
}

thead tr, thead th {
  border: none;
}

tbody tr td {
  color: #4F4F4F !important;
  border: none !important;
}

/**Style Booking And Subs */

/**Style SettingBooking */
.notify {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
}

.penTag {
  position: absolute;
  left: 3%;
  font-size: 20px;
  top: 5%;
  color: #4B5563;
}

/**Style SettingBooking */

/**Style Pagination */

.page-link {
  background-color: #f9f9f9 !important;
  color: #272727 !important;
  border-radius: 10px !important;
}

.page-link:hover {
  background-color: rgb(254, 203, 68) !important;
  color: white !important;
}

/**Style Pagination  */

input[type="tel"] {
  background-color: #EFF1F9 !important;
  width: 100% !important;
}

#custom-switch {
  padding: 8px;
  background-color: #BBC5CB !important;
  width: 40px;
  border: none !important;
}

#custom-switch:checked {
  background-color: #FECB44 !important;
}

/**Design Cars Details */
.circleCar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: auto;
}

/**Design Cars Details */
.itemBycker {
  background-color: #FFFAEC;
  color: #0B090A;
  padding: 5px;
  border-radius: 10px;
  width: 50px;
  display: inline;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/**Style Invoice */
.logoInvoice {
  /* background-color: white; */
  padding: 10px;
  width: 1px;
  height: 120px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.invoiceCompany {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/**Style Invoice */


/**bikerEditTop */
.bikerEditTop {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.textInputBiker {
  padding: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: #EFF1F9;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.timeAvailData {
  background: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 100%;
  margin-bottom: 15px;
}

/**bikerEditTop */

/* Style Area */
.showMap {
  color: #3CC441 !important;
  cursor: pointer;
}

.area {
  background: #EFF1F9;
  border: none;
  width: 100%;
  outline: none;
  padding: 18px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Style Area */
/* content Of Map */
.nameSpanBiker {
  color: #828282 !important;
  font-size: 20px !important;
  font-weight: 900 !important;
}

/* content Of Map */
/**Additional Serivce */
.additionalService {
  position: relative;
  cursor: pointer;
}

.serviceOptions {
  position: absolute;
  border-bottom: 1px solid #E5E7EB;
  background: #FFFAEC;
  padding: 10px;
  border-radius: 15px;
  /* top:100%;
  left:50%;
  transform: translate(-50%,-50%); */
  width: 100%;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.serviceOptions ul {
  list-style: none;
}

/* Message Sections */
.showMessage, .showMessageClose {
  color: #3CC441 !important;
  position: relative;
  cursor: pointer;
}

.showMessageClose {
  color: red !important;
}

.infoShowMessage {
  position: absolute;
  width: 300%;
  /* top:240%; */
  left: 50%;
  color: black;
  background: #FFFAEC;
  border-bottom: 1px solid #E5E7EB;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.textMessage {
  resize: none;
}

.addNew {
  background-color: #eeeae0;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px dashed #828282;
  cursor: pointer;
}

/* Message Sections */
/**Additional Serivce */
@media(max-width:767px) {
  .login {
    width: 75%;
  }

  .sidebar {
    width: 70% !important;
  }
}



/* //Verify-otp Desig  */

.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-image: url(./Images/backShadowLogin\ -\ Copy.WebP);
  -o-transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.otp-input-wrapper {
  margin: 0 5px;
  /* هامش بين المربعات */
}


.allOtb {
  width: 100%;
  height: 100vh;
  background-image: url(./Images/backShadowLogin\ -\ Copy.WebP);
  background-color: white !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.otp-input {
  direction: ltr;
}

.input-otp {
  width: 100px !important;
  /* Adjust the width as needed */
  height: 60px !important;
  /* Adjust the height as needed */
  text-align: center;
  margin: 0 5px;
  /* Adjust the spacing between the inputs */
  border: 1px solid #ccc;
  /* Border color */
  border-radius: 5px;
  /* Rounded corners */
  font-size: 16px;
  /* Adjust font size as needed */
  outline: none;
  /* Remove default outline */
}

.otp-container {
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.brandOption {
  padding-left: 30px;
  /* تحديد مساحة النص عند اليمين من الصورة */
  background-repeat: no-repeat;
  background-position: center left;
  /* تحديد موقع الصورة */
}




/* Manage Colors Style */
#colorCar {
  color: rgba(171, 175, 177, 1);
}

.addCarsColors {
  background-color: white;
  padding: 20px;
  margin-top: 25px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.w-color-alpha {
  margin: auto !important;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  width: 50% !important;
  position: absolute;
  top: 90px;
}

.choiceColors {
  width: 90%;
  margin: 10px auto;
  background-color: rgb(234, 226, 226);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.modalUpdateColor .w-color-alpha {
  margin: auto !important;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  width: 100% !important;
  position: absolute;
  top: 10px;
}

/* Manage Colors Style */

/* Gift  */
.flag {
  margin-right: 10px;
}

/* Gift  */


/**Manage Points  */
#userPoints {
  color: #A85923 !important;
  cursor: pointer;
  text-decoration: none;

}
.infoData{
  background-color: white;
  padding:10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
/**Manage Points  */

.selected {
  border: 3px solid black !important;
  /* background-color: red !important; */
  /* أو أي نمط حدد تفضله */
}




.GreenFlag , .RedFlag{
  width:20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.RedFlag{
  background-color: red;
}





/**Loaders */
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #000;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid rgb(254, 203, 68);
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
/**Loaders */


.cell-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center; /* لمحاذاة العناصر في المنتصف بشكل عمودي */
}


































.resizable-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Allow interaction with map underneath */
}

.handle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FF0000;
  cursor: pointer;
}

.handle:nth-child(1) {
  top: -5px;
  left: calc(50% - 5px);
}

.handle:nth-child(2) {
  bottom: -5px;
  left: calc(50% - 5px);
}

.handle:nth-child(3) {
  left: -5px;
  top: calc(50% - 5px);
}

.handle:nth-child(4) {
  right: -5px;
  top: calc(50% - 5px);
}


.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: #3e8e41;
}

#myInput {
  box-sizing: border-box;
  /* background-image: url('searchicon.png'); */
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}

#myInput:focus {outline: 3px solid #ddd;}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}



.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  /* تعديل خصائص الـ loader حسب تصميم الـ loader الخاص بك */
}

.showOnly{
  background-color: transparent !important;
  border: none !important;
}
.showOnly .form-control{
  border: none !important;
}
.showOnly  .rdtPicker{
  display: none !important;
}